<template>
    <div>
        <Head :title="'车辆审核'" :out="urls" />
        <div class="warper" >
            <div class="listbox" v-for="(item,i) in dataList" :key="item.id">
                <h3>{{item.name}}</h3>
                <div>
                    <van-image v-for="(i,index) in item.value" :key="index" width="100" height="100" :src="i" />
                </div>
                <!-- <van-button type="warning">添加照片异常</van-button> -->
                <van-cell center title="是否有异常">
                    <template #right-icon>
                        <van-switch v-model="item.checked" size="24" @change="checkedChange($event,i)" />
                    </template>
                </van-cell>
                <div class="yichang" v-if="item.checked ">
                    <!-- <p style="color:red">上传异常照片:</p> -->
                    <uploadingImg ref="business_license" :isTrue="false" :objectionValue="item.objection_value" @imgindex="imgindex($event,i)"  />

                    <van-field class="bb" v-model="item.objection_remark" label="异常备注" />
                </div>
            </div>
            <div class="mt-10">
                <van-button type="warning" @click="chu">清除</van-button>
                <div v-if="qianming" class="img"> 
                   
                    <van-image  :src="qianming" />
                </div>
                <div  v-if="!qianming">
                    <signature ref="tureRef" @isFile="isFile"  />
                </div>
            </div>
            <div class="btn">
                <van-button type="info" @click="nextGo">下一步</van-button>
            </div>
        </div>
        <van-popup
            v-model="show"
            :closeable="false"
            :close-on-click-overlay="false"
            position="bottom"
            :style="{height: '100%',}"
            >
            <TrailerInfo @CarCloes="CarCloes" ref="InfoRef" status="isAudit" />
        </van-popup>
    </div>
</template>

<script>
import {checkFind,submitCheck} from '@/api/audit'
import Head from '@/components/Head'
import uploadingImg from '@/components/up/uploadingImg'
import TrailerInfo from '@/components/TrailerInfo'
import signature from '@/components/signature'
    export default {
        components: {
            Head,uploadingImg,signature,TrailerInfo
        },
        data() {
            return {
                dataList:[],
                checked:false,
                show:false,
                qianming:''
            }
        },
        watch: {
            dataList: {
                immediate: true,
                deep: true,
                handler(newValue, oldValue) {
                
                    newValue.forEach(i => {
                        if(i.objection_value.length !=0){
                            i.checked = true
                        }
                    });
                }
            }
        },
        computed: {
            urls() {
                let a = ''
                if(this.$route.query.url == 'tuoyun'){
                    a='PreserveTheSend?active=4'
                }else  if(this.$route.query.url == 'acdit'){
                    a='BqList?active=0'
                }
                return a
            }
        },
        mounted () {
           
                this.getInfo();
                this.chu()
            
        },
        methods: {
            CarCloes(){
                this.show = false
                this.getInfo();
            },
            //清除画布签名
            chu(){
                if(this.qianming){

                    this.qianming = ''
                }else{

                    this.$refs.tureRef.clearSignImg()
                }
            },
          
            //关闭胶南按钮
            checkedChange(e,i){
                console.log(e)
                if(!e){
                    this.dataList[i].objection_value = []
                    this.dataList[i].objection_remark = []
                    this.$nextTick(()=>{
                        this.$refs.business_license.WXimg = []
                    })
                }
            },
            //获取照片后的提交
            async isFile(e){
                try{
                    let obj = {
                        id:this.$route.query.id,
                        objection_data:this.dataList,
                        objection_signature:e
                    }
                    const {data} = await submitCheck(obj).then(res=>res)
                    this.show = true
                    this.$nextTick(()=>{
                        this.$refs.InfoRef.getInfo(this.$route.query.id)
                    })
                    this.$toast.success(data.msg)
                }catch{}
            },
            //获取异常照片
            imgindex(i,val){
                console.log(i,val)
                this.dataList[val].objection_value = i
            },
            //提交信息
            nextGo(){
                if(this.$route.query.ids == 1){
                    if(this.qianming){
                        this.isFile(this.qianming)
                    }else{
                        this.$refs.tureRef.saveSignImg()
                    }
                }else if(this.$route.query.ids == 2){
                    this.show = true
                    this.$nextTick(()=>{
                        this.$refs.InfoRef.getInfo(this.$route.query.id)
                    })
                }
                
                
                
            },  
            //获取信息
            async getInfo() {
                try{
                    const {data} = await checkFind({id:this.$route.query.id}).then(res=>res)
                    console.log(data)
                    this.dataList = data.data.image
                    this.qianming = data.data.objection_signature
                }catch{}
                
            }
        },
    }
</script>

<style lang="scss" scoped>
.warper{
    margin-top: 55px;
    padding: 10px;
    .listbox{
        border-bottom: 1px solid #000;
        padding-bottom: 20px;
        .yichang{
            
            .bb{
                border-bottom: 1px solid rgb(207, 200, 200);
            }
        }
    }
}
.btn{
    text-align: center;
    margin-top: 20px;
    .van-button{
        width: 150px;
    }
}
.img{
    background: #eee;
    position: relative;
}
</style>